<template>
  <div id="app">
    <AppHeader v-show="!['One','Three','Four','Enter','Environment','Autograph'].includes($route.name) && guideKey == true" ></AppHeader>
    <div class="shareBox" :class="{show:shareKey}">
      <img src="../static/img/share1.png" class="guide" alt="">
      <img src="../static/img/share2.png" class="confirm" @click="$store.dispatch('changeShare',false)" alt="">
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import AppHeader from './components/app-header'
import { mapState } from 'vuex'
export default {
  name: 'app',
  components: {
    AppHeader
  },
  computed: {
    ...mapState(['guideKey','shareKey'])
  },
}
</script>

<style lang="scss" scoped>
.shareBox{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  transform: scale(0);
  background-color: rgba(0,0,0,0.7);
  &.show {
    animation: scaleFrames .4s cubic-bezier(.5, .49, .57, 1.41) forwards;
  }
  @keyframes scaleFrames {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
  .guide{
    position: absolute;
    right: 0;
    top: 0;
    width: 72%;
  }
  .confirm{
    position: absolute;
    right: 10%;
    bottom: 35%;
    width: 50%;
  }
}
</style>
