import router from "@/router";
import store from "@/store";

function getUrlParam(key) {
    const reg = new RegExp('[?|&]' + key + '=([^&]+)')
    const match = window.location.href.match(reg)
    return match && match[1]
}

router.beforeEach((to, from, next) => {
    let token = store.state.info.openId;
    if (to.path !== '/author' && to.path !== '/') { // /(第一次)
        sessionStorage.path = to.fullPath;
    }
    if (/code/.test(window.location.href) && window.location.href.startsWith(`${window.location.origin}/?code`)) {
        const code = getUrlParam('code')
        window.location.replace(`https://hr.eainger.cn/#/author?code=${code}`)
        return
    }
    if (!token && !['/companyintroduce','/enter','/guide','/one','/three','/four','/glory','/environment'].includes(to.path)) {
        if (to.path === '/author') {
            next()
        } else {
            next({
                path: '/author'
            })
        }
    } else {
        next()
    }
})
