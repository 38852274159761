<template>
  <div class="header_box">
    <div class="header">
      <div class="header_left">
        <router-link class="logo" :to="{name: 'Home'}">
          <img src="../../static/img/logo.jpg" alt="">
        </router-link>
        <p class="title">人事助手</p>
      </div>
      <div class="header_right">
        <p class="location">{{location}} <span v-show="location !== ''">/</span></p>
        <van-icon  v-show="$route.name != 'RecruitmentList'" @click.prevent="show= !show" class="icon" :name="show == true ? 'cross':'wap-nav'" size="0.8rem" color="#666666" />
      </div>
    </div>
    <div class="menu" :class="{showMenu : show}">
      <van-cell title="面试" @click.prevent="jump('RecruitmentList')"/>
      <van-cell title="入职" @click.prevent="jump('Entry')"/>
      <van-cell title="入职小测试" @click.prevent="jump('EntryTest')"/>
      <van-cell title="面试官" @click.prevent="judge('Login')"/>
      <van-cell title="进度" @click.prevent="jump('Search')"/>
      <van-cell title="线路指引" @click.prevent="jump('Guide')"/>
      <van-cell title="入职指引" @click.prevent="jump('Enter')"/>
      <van-cell title="公司荣耀" @click.prevent="jump('Glory')"/>
      <van-cell title="办公环境" @click.prevent="jump('Environment')"/>
    </div>
    <van-overlay z-index="98" :show="show" @click="show= false" />
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {GetApprovalList} from "@/api/examiner";
  export default {
    name: "app-header",
    computed: mapState([
      'location'
    ]),
    data(){
      return {
        show: false
      }
    },
    methods: {
      jump(name){
        this.show = false;
        if(this.$route.name===name) return;
        this.$router.push({
          name: name
        })
      },
      async judge(name){
        if(this.$route.name===name){this.show=false; return;}
        await GetApprovalList({openId:this.$store.state.info.openId}).then(res=>{
          if(res.errcode === 401){
            this.$router.push({
              name: 'Login'
            })
            this.show = false;
            return
          }
          if(res.errcode == 200){
            this.$router.push({
              name: 'ApprovalList',
              query: {
                data: res.rows
              }
            })
            this.show = false
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.header_box{
  width: 100%;
  padding-bottom: 54px;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 10px;
    position: fixed;
    top: 0;
    width: inherit;
    z-index: 99;
    background-color: #fff;
    .header_left{
      display: flex;
      align-items: center;
      .logo{
        img{
          width: 63px;
          //height: 26px;
        }
      }
      .title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: normal;
        color: #333333;
        line-height: 18px;
        margin-left: 9px;
      }
    }
    .header_right{
      display: flex;
      align-items: center;
      .location{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #666666;
        margin-right: 10px;
      }
      .icon{
        cursor: pointer;
        transition: 0.3s all ease;
      }
    }
  }
  .menu{
    position: fixed;
    top: 53px;
    width: inherit;
    z-index: 99;
    transform: scaleY(0);
    //transform: translate(-100%);
    transition: 0.3s all ease;
  }
  .van-overlay{
    background-color: rgba(0,0,0,.46);
  }
  .showMenu{
    transform: scaleY(1);
    //transform: translateX(0%);
  }
}
</style>
