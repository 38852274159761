<template>
  <div class="home">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="../../static/img/banner_02.jpg" alt="">
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../static/img/banner_02.jpg" alt="">
      </van-swipe-item>
      <van-swipe-item>
        <img src="../../static/img/banner_02.jpg" alt="">
      </van-swipe-item>
    </van-swipe>
    <div class="operate">
      <router-link class="operate_item" :to="{name:'RecruitmentList'}">
        <img class="icon" src="../../static/img/operate_03.jpg" alt="">
        <p class="txt">面试</p>
      </router-link>
      <router-link class="operate_item" :to="{name:'Entry'}">
        <img class="icon" src="../../static/img/operate_05.jpg" alt="">
        <p class="txt">入职</p>
      </router-link>
<!--      <router-link class="operate_item" :to="{name:'Home'}">-->
<!--        <img class="icon" src="../../static/img/operate_07.jpg" alt="">-->
<!--        <p class="txt">资料</p>-->
<!--      </router-link>-->
      <div class="operate_item" @click="judge()">
        <img class="icon" src="../../static/img/operate_09.jpg" alt="">
        <p class="txt">面试官</p>
      </div>
    </div>
    <div class="linear"></div>
    <div class="content">
      <div class="title">
        <h3>公司介绍</h3>
        <router-link :to="{name:'Introduce'}" class="lookmore">查看更多 ></router-link>
      </div>
      <div class="con">
        <p class="text">易安捷供应链有限公司是由临沂城市发展集团有限公司、临沂市兰山区财金投资集团有限公司以及山东新明辉供应链有限公司共同出资成立的供应链企业，注册资金壹亿元，企业主要经营业务为仓储投资、供应链金融、软件开发、电子商务、互联网销售等。</p>
        <p class="text">公司整合股东优势，依托数字化信息技术，打造全国首个国际工程物资采购平台，依托两大国企强大的资金实力和新明辉在工业品行业卓越的数字化能力，高效聚合供应链上下游资源，形成线上线下融合的工程物资交易平台，并在仓储物流、企业数字化转型发展中赋能上下游企业。</p>
      </div>
    </div>
    <router-link :to="{name:'Search'}" tag="p" class="look_progress">查看进度</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import {GetApprovalList} from "@/api/examiner";

export default {
  name: 'Home',
  created() {
    this.$store.dispatch('changeLocation', '')
  },
  methods: {
    async judge() {
      await GetApprovalList({openId:this.$store.state.info.openId}).then(res=>{
        if(res.errcode === 401){
          this.$router.push({
            name: 'Login'
          })
          this.show = false;
          return
        }
        if(res.errcode == 200){
          this.$router.push({
            name: 'ApprovalList',
            query: {
              data: res.rows
            }
          })
          this.show = false
        }
      })
    },
    judgeEntry(){
      if(this.$store.state.info.re_empstatus !=1 || this.$store.state.info.re_empstatus !=4){
        this.$toast('暂时禁止进入')
        return;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  .my-swipe {
    width: 100%;
    height: 265px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .operate {
    display: flex;
    align-items: center;
    padding: 20px 0;

    .operate_item {
      flex: 1;

      .icon {
        display: block;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        margin: 0 auto;
      }

      .txt {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: normal;
        color: #343434;
        margin-top: 12px;
        line-height: 16px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .linear {
    width: 100%;
    height: 10px;
    background: #F7F7F7;
  }

  .content {
    box-sizing: border-box;
    padding: 0 15px 85px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 25px;

      h3 {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 700;
        color: #333333;
        margin: 0;
        line-height: 18px;
      }

      .lookmore {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: normal;
        color: #666666;
      }
    }

    .con {
      padding-top: 18px;

      .text {
        text-align: justify;
        text-indent: 2em;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        color: #333333;
      }
    }
  }

  .look_progress {
    font-family: Microsoft YaHei;
    width: 100%;
    height: 52px;
    background-color: #02DBCA;
    font-size: 18px;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    line-height: 52px;
    position: fixed;
    bottom: 0;
    cursor: pointer;
    margin: 0;
  }
}
</style>
