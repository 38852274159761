import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //授权页
  {
    path: '/author',
    name: 'Author',
    component: () => import('../views/Author')
  },
  //面试官登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  //  进度查询
  {
    path: '/searchprogress',
    name: 'Search',
    component: () => import('../views/SearchProgress')
  },
  //  公司介绍
  {
    path: '/companyintroduce',
    name: 'Introduce',
    component: () => import('../views/CompanyIntroduce')
  },
  //  招聘列表
  {
    path: '/recruitmentlist',
    name: 'RecruitmentList',
    component: () => import('../views/RecruitmentList')
  },
  //  职位详情
  {
    path: '/postDetail',
    name: 'PostDetail',
    component: () => import('../views/PostDetail')
  },
  //  面试登记
  {
    path: '/interviewrregistration',
    name: 'Interview',
    component: () => import('../views/InterviewRegistration')
  },
  //  入职登记
  {
    path: '/entryregistration',
    name: 'Entry',
    component: () => import('../views/EntryRegistration')
  },
  //  待我审批
  {
    path: '/approvallist',
    name: 'ApprovalList',
    component: () => import('../views/ApprovalList')
  },
  //  审批详细
  {
    path: '/applicationdetails',
    name: 'ApplicationDetails',
    component: () => import('../views/ApplicationDetails')
  },
  //  入职指引
  {
    path: '/enter',
    name: 'Enter',
    component: () => import('../views/guide/Enter')
  },
  //  入职小测试
  {
    path: '/EntryTest',
    name: 'EntryTest',
    component: () => import('../views/EntryTest')
  },
  //  面试指引
  {
    path: '/guide',
    name: 'Guide',
    component: () => import('../views/guide/Index')
  },
  //  工业园区
  {
    path: '/one',
    name: 'One',
    component: () => import('../views/guide/One')
  },
  //  五路办公室
  {
    path: '/three',
    name: 'Three',
    component: () => import('../views/guide/Three')
  },
  //  金泉办公区
  {
    path: '/four',
    name: 'Four',
    component: () => import('../views/guide/Four')
  },
  //  公司荣耀
  {
    path: '/glory',
    name: 'Glory',
    component: () => import('../views/guide/Glory')
  },
  //  办公环境
  {
    path: '/environment',
    name: 'Environment',
    component: () => import('../views/guide/Environment')
  },

    //签名
  {
    path: '/autograph',
    name: 'Autograph',
    component: () => import('../views/autograph/index')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'hash'
})

export default router
